/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
@import "~@angular/material/theming";
@include mat.core();

$angular-primary: mat.define-palette(mat.$blue-palette, 500, 100, 900);
$angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$angular-warn: mat.define-palette(mat.$red-palette);

$angular-default-theme: mat.define-light-theme(
  (
    color: (
      primary: $angular-primary,
      accent: $angular-accent,
      warn: $angular-warn,
    ),
  )
);

@include mat.all-component-themes($angular-default-theme);

$angular-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $angular-primary,
      accent: $angular-accent,
      warn: $angular-warn,
    ),
  )
);

.darkMode {
  @include mat.all-component-colors($angular-dark-theme);
}

html, body, #app {
    height: 100%;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
}

html {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    overflow: auto;
}

body {
    padding: 1rem;
    overflow: auto;
}

html, body { height: 100%; }
body { margin: 0; padding:0; font-family: Roboto, "Helvetica Neue", sans-serif; }
